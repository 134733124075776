<template>
  <a-page-header
    @back="() => $router.go(-1)"
    title="EXPORTAR DATOS COMPLEMENTARIOS"
    class="pt-0 px-0"
  >
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Import./Export.</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Exportar base de datos</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <div class="flex flex-wrap mb-4">
    <fieldset class="border border-solid border-gray-300 p-3 px-8">
      <legend class="text-sm text-left pl-4">Filtra la información</legend>
      <ExportarFiltros @reloadFetch="fetchData" :isExportarDisabled="isExportarDisabled" />
    </fieldset>

    <!--------------- Acciones--------------->
    <fieldset
      class="border border-solid w-full text-center border-gray-300 p-3 px-8 md:w-1/2 lg:w-auto"
    >
      <legend class="text-sm text-center">Acciones</legend>
      <button
        @click="downloadSQL"
        type="button"
        class="text-center px-3"
        :disabled="isExportarDisabled"
      >
        <div
          class="text-3xl"
          :class="[isExportarDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-green-600']"
        >
          <DownloadOutlined />
        </div>
        <p>Exportar</p>
      </button>
    </fieldset>
  </div>
  <a-spin tip="Descargando Información..." :spinning="spinning">
    <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
      <a-table
        :row-selection="rowSelection"
        :columns="columns"
        :data-source="data"
        size="small"
        :pagination="false"
        rowKey="TABLE_NAME"
        :loading="isLoading"
        :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
      >
        <template #rangepages="{ index }">
          <span> {{ index + 1 }} </span>
        </template>

        <template #activo="{ text: activo }">
          <span>
            <a-tag :color="activo === '1' ? 'green' : 'volcano'">
              {{ activo === "1" ? "Activado" : "Desactivado" }}
            </a-tag>
          </span>
        </template>
      </a-table>
    </div>
  </a-spin>

  <footer class="flex items-center justify-between pb-6 pt-5 border-t-2">
    <div class="flex">
      <button class="ml-4" @click="fetchData">
        <ReloadOutlined />
      </button>
    </div>
    <p>Total de registros: {{ totalDocs }}</p>
  </footer>
</template>

<script>
import { ref, reactive, computed, toRaw, onMounted, watch } from "vue";
import { columns, breadcrumb } from "./utilsExportar";
import { useStore } from "vuex";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons-vue";
import ApiBackup from "@/api/backup";
import ExportarFiltros from "./ExportarFiltros.vue";
import ExportarApi from "@/api/exportar";
import router from "@/router";
import UsuariosApi from "@/api/user";
import { numberWithCommas } from "@/utils";

export default {
  name: "AppExportar",
  components: {
    ExportarFiltros,
    // icons
    ReloadOutlined,
    DownloadOutlined
  },
  setup() {
    const store = useStore();
    const isVisible = ref(false);
    const totalDocs = ref(null);
    const data = ref([]);
    const spinning = ref(false);
    const isLoading = ref(false);
    const isExportarDisabled = ref(true);
    const state = reactive({
      selectedRowKeys: []
    });

    // ------- onBack -------
    const goBack = () => router.back();
    const { establecimiento } = store.state.user.usuario;

    // -------- se ejecuta cuando selecciona una fila --------
    const rowSelection = computed(() => {
      return {
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys) => {
          console.log(selectedRowKeys);

          state.selectedRowKeys = selectedRowKeys;
        }
      };
    });

    const downloadSQL = () => {
      toRaw(state.selectedRowKeys).forEach(async (element) => {
        await ApiBackup.getBackup(element);
      });
    };

    // ------- trae datos de la api --------
    const fetchData = () => {
      const search = store.getters["exportar/search"];
      isLoading.value = true;
      ExportarApi.getAll({ search })
        .then((response) => {
          console.log(response);
          data.value = response.data;

          totalDocs.value = response.data.length;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    onMounted(() => {
      UsuariosApi.userLogAdd("Import./Export.", "Exportar", establecimiento);
      fetchData();
    });

    watch(state, () => {
      isExportarDisabled.value = !state.selectedRowKeys.length;
    });

    const datosFormated = computed(() =>
      data.value.map((d) => {
        return {
          ...d,
          TABLE_ROWS_f: numberWithCommas(d.TABLE_ROWS)
        };
      })
    );

    return {
      routes: breadcrumb,
      isLoading,
      rowSelection,
      totalDocs,
      // customRow,
      columns,
      downloadSQL,
      data: datosFormated,
      fetchData,
      isVisible,
      spinning,
      goBack,
      isExportarDisabled
    };
  }
};
</script>

<style></style>
