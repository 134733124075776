export const columns = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "NOMBRE DE TABLA",
    dataIndex: "TABLE_NAME",
    key: "TABLE_NAME",
    width: 190
  },
  {
    title: "TOTAL DE REGISTROS",
    dataIndex: "TABLE_ROWS_f",
    key: "TABLE_ROWS_f",
    width: 100
  }
  // {
  //   title: "COLLATION",
  //   dataIndex: "TABLE_COLLATION",
  //   key: "TABLE_COLLATION",
  //   width: 120
  // },
  // {
  //   title: "FECHA DE CARGA",
  //   dataIndex: "UPDATE_TIME",
  //   key: "UPDATE_TIME",
  //   width: 100
  // }
];

export const breadcrumb = [
  {
    path: "app",
    breadcrumbName: "Import./Export."
  },
  {
    path: "/exportar",
    breadcrumbName: "Exportar información"
  }
];
