import { post } from "./base_backup";

const API_SERVICE_BACKUP = "/api/Service/backup";
const API_SERVICE_BACKUP_SIVINUR = "/api/Service/backup_sivinur";
const API_SERVICE_BACKUP_CONFIGURACION = "/api/Service/backup_configuracion";
const API_SERVICE_BACKUP_DATAHOUSE = "/api/Service/backup_datahouse";
const API_SERVICE_BACKUP_MIGRATE = "/api/Service/backup_migrate";

export default {
  getBackup: (tablas) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "@tablas",
          value: `${tablas}`,
          type: "array"
        }
      ]
    };

    return post(API_SERVICE_BACKUP, request);
  },

  getBackupSivinur: () => {
    const request = {
      _type: 1,
      _table: "sivinur",
      _petitions: []
    };

    return post(API_SERVICE_BACKUP_SIVINUR, request);
  },

  getBackupConfiguracion: () => {
    const request = {
      _type: 1,
      _table: "sivinur",
      _petitions: []
    };

    return post(API_SERVICE_BACKUP_CONFIGURACION, request);
  },

  getBackupDataHouse: () => {
    const request = {
      _type: 1,
      _table: "sivinur",
      _petitions: []
    };

    return post(API_SERVICE_BACKUP_DATAHOUSE, request);
  },

  getBackupMigrate: () => {
    const request = {
      _type: 1,
      _table: "sivinur",
      _petitions: []
    };

    return post(API_SERVICE_BACKUP_MIGRATE, request);
  }
};
